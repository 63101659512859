<template>
  <!--
    A collection of functions and components related to one device.
  -->
  <div>
    <Portlet
      :title="$t('menu.resorts')"
      icon="map-marked-alt"
      class="resorts"
    >
      <template
        v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
        slot="buttons"
      >
        <router-link
          :to="{ path: `/resorts/add` }"
          class="btn btn-sm btn-primary float-right" 
        >
          <font-awesome-icon
            class="mr-1"
            icon="plus"
          />
          {{ $t('create') }}  {{ $t('menu.resort') }}
        </router-link>
        <div class="clearfix" />
      </template>
      <a
        v-if="selectedResort != -1"
        :href="getSkilineUrl()"
        class="btn btn-sm btn-primary float-right"
        target="_blank"
      >{{ 'To Skiline.cc' }}</a>
      <ResortSelection
        :id="id" 
        :name="name" 
        @resortSelected="selectResort"
      />
      <ul class="nav nav-tabs mt-4">
        <li
          class="nav-item"
          @click="tabOpened('reportTab'); wasReportTabOpened = true;"
        >
          <a
            ref="reportTab"
            class="nav-link"
            href="#reports"
            data-toggle="tab"
            aria-controls="reports"
            aria-selected="true"
          >{{ 'Reporting' }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('detailsTab'); wasDetailsTabOpened = true;"
        >
          <a
            ref="detailsTab"
            :class="['nav-link', { 'active' : !authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')}]"
            href="#details"
            data-toggle="tab"
            aria-controls="details"
            aria-selected="true"
          >{{ $t('details') }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('logosTab'); wasLogosTabOpened = true;"
        >
          <a
            ref="logosTab"
            class="nav-link"
            href="#logos"
            data-toggle="tab"
            aria-controls="logos"
            aria-selected="true"
          >Logos</a>
        </li>
        <li
          class="nav-item"
          @click="tabOpened('installationTab'); wasInstallationsTabOpened = true;"
        >
          <a
            ref="installationTab"
            :class="['nav-link', { 'active' : authenticationHasRole('zerolevel') || authenticationHasRole('firstlevel') || authenticationHasRole('firstlevel_connector')}]"
            href="#installationsList"
            data-toggle="tab"
            aria-controls="installationsList"
            aria-selected="true"
          >{{ $t('installations') }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('converterTab'); wasConverterTabOpened = true;"
        >
          <a
            ref="converterTab"
            class="nav-link"
            href="#converterConfigs"
            data-toggle="tab"
            aria-controls="converterConfigs"
            aria-selected="true"
          >{{ $t('menu.converterConfigs') }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('spotListTab'); wasSpotTabOpened = true;"
        >
          <a
            ref="spotListTab"
            class="nav-link"
            href="#spotList"
            data-toggle="tab"
            aria-controls="spotList"
            aria-selected="true"
          >{{ 'Spot' }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('photopointListTab'); wasPhotopointTabOpened = true;"
        >
          <a
            ref="photopointListTab"
            class="nav-link"
            href="#photopointList"
            data-toggle="tab"
            aria-controls="photopointList"
            aria-selected="true"
          >{{ 'Photopoint' }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('liftTab'); wasLiftOpened = true;"
        >
          <a
            ref="liftTab"
            class="nav-link"
            href="#lift"
            data-toggle="tab"
            aria-controls="lift"
            aria-selected="true"
          >{{ 'Sections' }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('routingRulesTab'); wasRoutingRulesOpened = true;"
        >
          <a
            ref="routingRulesTab"
            class="nav-link"
            href="#routingRules"
            data-toggle="tab"
            aria-controls="routingRules"
            aria-selected="true"
          >{{ $t('routingRulesForms.routingRules') }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('sharedTab'); wasSharedOpened = true;"
        >
          <a
            ref="sharedTab"
            class="nav-link"
            href="#shared"
            data-toggle="tab"
            aria-controls="shared"
            aria-selected="true"
          >{{ 'Shared' }}</a>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')"
          class="nav-item"
          @click="tabOpened('permissionTab'); wasPermissionOpened = true;"
        >
          <a
            ref="permissionTab"
            class="nav-link"
            href="#permission"
            data-toggle="tab"
            aria-controls="permission"
            aria-selected="true"
          >{{ $t('permissionsBtoB') }}</a>
        </li>
        <li
          v-if="selectedResort != -1 && isConverterType && openedTab == 'converterTab'"
          class="nav-item"
          style="margin-left: auto;"
        >
          <button 
            class="btn btn-sm btn-primary float-right" 
            @click="showSidebarAdd"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t('converterConfigs.add') }}
            </span>
          </button>
        </li>
        <li
          v-if="selectedResort != -1 && openedTab == 'spotListTab'"
          class="nav-item"
          style="margin-left: auto;"
        >
          <a
            :href="`https://admin.skiline.cc/php/alturos/content/admin/id/tool/spotCreate.php?resortId=${ selectedResort }`"
            target="_blank"
            class="cursor-pointer btn btn-sm btn-primary float-right"
          ><font-awesome-icon
             class="mr-1"
             icon="plus"
           />
            <span>
              {{ $t('resortSpotAndPhotopoint.addSpot') }}
            </span></a>
        <!-- <button 
          class="btn btn-sm btn-primary float-right" 
          @click="showSpotSidebarAdd"
        >
        </button> 
        -->
        </li>
        <li
          v-if="selectedResort != -1 && openedTab == 'photopointListTab'"
          class="nav-item"
          style="margin-left: auto;"
        >
          <a
            :href="`https://admin.skiline.cc/php/alturos/content/admin/id/tool/photoPointCreate.php?resortId=${ selectedResort }`"
            target="_blank"
            class="cursor-pointer btn btn-sm btn-primary float-right"
          ><font-awesome-icon
             class="mr-1"
             icon="plus"
           />
            <span>
              {{ $t('resortSpotAndPhotopoint.addPhotopoint') }}
            </span></a>
        <!-- <button 
          class="btn btn-sm btn-primary float-right" 
          @click="showPhotopointSidebarAdd"
        >
        </button> 
        -->
        </li>
        <li
          v-if="selectedResort != -1 && openedTab == 'liftTab'"
          class="nav-item"
          style="margin-left: auto;"
        >
          <button 
            class="btn btn-sm btn-primary float-right" 
            @click="openLiftExportModal"
          >
            <font-awesome-icon
              class="mr-1"
              icon="file-export"
            />
            <span>
              {{ 'Export' }}
            </span>
          </button>
        </li>
        <li
          v-if="selectedResort != -1 && openedTab == 'liftTab'"
          class="nav-item"
        >
          <button 
            class="btn btn-sm btn-primary float-right" 
            @click="showLiftSidebarAdd"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t('resortSpotAndPhotopoint.addLift') }}
            </span>
          </button>
        </li>
        <li
          v-if="selectedResort != -1 && openedTab == 'sharedTab'"
          class="nav-item"
          style="margin-left: auto;"
        >
          <button 
            class="btn btn-sm btn-primary float-right" 
            @click="showSharedSidebarAdd"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t('sharedResortForms.addSharedResort') }}
            </span>
          </button>
        </li>
        <li
          v-if="selectedResort != -1 && openedTab == 'routingRulesTab'"
          class="nav-item"
          style="margin-left: auto;"
        >
          <button 
            class="btn btn-sm btn-primary float-right" 
            @click="showRoutingRulesSidebarAdd"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t('routingRulesForms.addRoutingRules') }}
            </span>
          </button>
        </li>
      </ul>

      <div class="tab-content">
        <div
          id="reports"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="reports"
        >
          <LoadingPlaceholder v-if="loading" />
          <ReportingComponent
            v-else-if="wasReportTabOpened"
            :resort-id="selectedResort"
            :installations="installations"
          /> 
        </div>
        <div
          id="details"
          :class="['rep-tab-pane', { 'active' : !authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector')}]"
          role="tabpanel"
          aria-labelledby="details"
        >
          <LoadingPlaceholder v-if="loading" />
          <ResortDetails
            v-else-if="selectedResort != -1 && wasDetailsTabOpened"
            :resort-id="selectedResort"
          />
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="logos"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="logos"
        >
          <LoadingPlaceholder v-if="loading" />
          <ResortLogo
            v-else-if="selectedResort != -1 && wasLogosTabOpened"
            :resort-id="selectedResort"
          />
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="installationsList"
          :class="['rep-tab-pane', { 'active' : authenticationHasRole('zerolevel') || authenticationHasRole('firstlevel') || authenticationHasRole('firstlevel_connector')}]"
          role="tabpanel"
          aria-labelledby="installationsList"
        >
          <LoadingPlaceholder v-if="loading" />
          <InstallationList 
            v-else-if="selectedResort != -1 && wasInstallationsTabOpened"
            :show-all="true"
            :resort-id="selectedResort"
            :is-resort="true"
            :r-installations="installations"
          />
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="converterConfigs"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="converterConfigs"
        >
          <p v-if="!isConverterType && selectedResort != -1">
            {{ "Resort has not a connector type of CONVERTER." }}
          </p>
          <template
            v-else-if="selectedResort != -1 && wasConverterTabOpened"
          >
            <converterConfigsList
              ref="converterConfigsList"
              :resort-id="selectedResort"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :resort-id="selectedResort"
              @reload="reloadConverterConfigs"
            /> 
          </template>
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="spotList"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="spotList"
        >
          <template
            v-if="selectedResort != -1 && wasSpotTabOpened"
          >
            <SpotList
              ref="spotList"
              :resort-id="selectedResort"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :is-spots="true"
              :parent-resort-id="selectedResort"
              @reload="reloadSpots"
            />
          </template>
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="photopointList"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="photopointList"
        >
          <template
            v-if="selectedResort != -1 && wasPhotopointTabOpened"
          >
            <PhotopointList
              ref="photopointList"
              :resort-id="selectedResort"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :is-photopoint="true"
              :parent-resort-id="selectedResort"
              @reload="reloadPhotopoints"
            />
          </template>
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="lift"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="lift"
        >
          <template
            v-if="selectedResort != -1 && wasLiftOpened"
          >
            <ResortLift
              ref="resortLift"
              :resort-id="selectedResort"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :is-lift="true"
              :parent-resort-id="selectedResort"
              @reload="reloadLifts"
            />
          </template>
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="shared"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="shared"
        >
          <template
            v-if="selectedResort != -1 && wasSharedOpened"
          >
            <SharedResortsList
              ref="sharedResortsList"
              :resort-id="selectedResort"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :is-shared-resort="true"
              :parent-resort-id="selectedResort"
              @reload="reloadSharedResorts"
            />
          </template>
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="routingRules"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="routingRules"
        >
          <template
            v-if="selectedResort != -1 && wasRoutingRulesOpened"
          >
            <RoutingRulesList
              ref="routingRulesList"
              :resort-id="selectedResort"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :is-routing-rules="true"
              :parent-resort-id="selectedResort"
              @reload="reloadRoutingRules"
            />
          </template>
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
        <div
          id="permission"
          class="rep-tab-pane"
          role="tabpanel"
          aria-labelledby="permission"
        >
          <template
            v-if="selectedResort != -1 && wasPermissionOpened"
          >
            <iframe
              class="iframe"
              :src="`https://admin.skiline.cc/php/alturos/content/admin/id/tool/resortPermissions.php?resortId=${ selectedResort }`"
            />
          </template>
          <p v-else>
            {{ $t('pleaseSelectResort') }}
          </p>
        </div>
      </div>
    </Portlet>
    <SweetModal
      ref="liftExportModal"
      icon="file-export"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('resortForm.liftExportTitle') }}</p>
      <LoadingPlaceholder v-if="exportLoading" />
      <form
        v-else
        class="mt-5"
      >
        <div class="row">
          <div class="col-sm">
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportId"
                  v-model="checkedLiftFields"
                  value="Id"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportId"
                >Id</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportName"
                  v-model="checkedLiftFields"
                  value="Name"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportName"
                >Name</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportDuration"
                  v-model="checkedLiftFields"
                  value="Duration"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportDuration"
                >{{ $t('duration') }}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportLength"
                  v-model="checkedLiftFields"
                  value="Length"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportLength"
                >{{ $t('length') }}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportUniqueLiftName"
                  v-model="checkedLiftFields"
                  value="Unique Lift Name"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportUniqueLiftName"
                >{{ $t('resortForm.uniqueNameLift') }}</label>
              </div>
            </div>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportType"
                  v-model="checkedLiftFields"
                  value="Type"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportType"
                >{{ $t('type') }}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportGgnr"
                  v-model="checkedLiftFields"
                  value="GGNR"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportGgnr"
                >{{ 'GGNR' }}</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportCPointStartName"
                  v-model="checkedLiftFields"
                  value="CPoint Start Name"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportCPointStartName"
                >C-Point Start Name</label>
              </div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  id="exportCPointEndName"
                  v-model="checkedLiftFields"
                  value="CPoint End Name"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="exportCPointEndName"
                >C-Point End Name</label>
              </div>
            </div>
          </div>
        </div>
      </form>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.liftExportModal.close()"
      >
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="exportLift()"
      >
        <span>{{ 'Export' }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'ResortsView',
  components: {
    ResortSelection: () => import('@/components/Reporting/ResortSelection.vue'),
    InstallationList: () => import('@/components/Installation/InstallationList.vue'),
    ConverterConfigsList: () => import('@/components/ConverterConfigs/ConverterConfigsList.vue'),
    ReportingComponent: () => import('@/components/Reporting/ReportingComponent.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue'),
    ResortDetails: () => import('@/components/Resort/ResortDetails.vue'),
    SpotList: () => import('@/components/Resort/SpotList.vue'),
    PhotopointList: () => import('@/components/Resort/PhotopointList.vue'),
    ResortLift: () => import('@/components/Resort/ResortLift.vue'),
    ResortLogo: () => import('@/components/Resort/ResortLogo.vue'),
    SharedResortsList: () => import('@/components/Resort/SharedResortsList.vue'),
    RoutingRulesList: () => import('@/components/Resort/RoutingRulesList.vue'),
    SweetModal
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selectedResort: -1,
      installations: null,
      loading: false,
      resort: null,
      openedTab: this.authenticationHasRole('zerolevel') || this.authenticationHasRole('firstlevel') || this.authenticationHasRole('firstlevel_connector') ? 'installationTab' : 'detailsTab',
      wasReportTabOpened: false,
      wasDetailsTabOpened: false,
      wasLogosTabOpened: false,
      wasInstallationsTabOpened: false,
      wasConverterTabOpened: false,
      wasSpotTabOpened: false,
      wasPhotopointTabOpened: false,
      wasLiftOpened: false,
      wasSharedOpened: false,
      wasRoutingRulesOpened: false,
      wasPermissionOpened: false,
      checkedLiftFields: [],
      exportLoading: false
    }  
  },
  computed: {
    isConverterType () {
      return this.resort && (this.resort.connectorType == "CONVERTER" || this.resort.connectorType == "SKIDATA_AXESS_3TAELER");
    }
  },
  metaInfo () {
      return {
          title: this.$t('menu.resorts')
      }
  },
  methods: {
    openLiftExportModal () {
      this.$refs.liftExportModal.open();
    },
    tabOpened (data) {
      this.openedTab = data;
    },
    reloadConverterConfigs () {
      this.$refs.converterConfigsList.reloadConverterConfigs();
    },
    reloadSpots () {
      this.$refs.spotList.reloadSpots();
    },
    reloadPhotopoints () {
      this.$refs.photopointList.reloadPhotopoints();
    },
    reloadLifts () {
      this.$refs.resortLift.reloadLifts();
    },
    reloadSharedResorts () {
      this.$refs.sharedResortsList.reloadSharedResorts();
    },
    reloadRoutingRules () {
      this.$refs.routingRulesList.reloadRoutingRules();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    selectResort (val) {
      this.wasReportTabOpened = this.openedTab == 'reportTab';
      this.wasDetailsTabOpened = this.openedTab == 'detailsTab';
      this.wasLogosTabOpened = this.openedTab == 'logosTab';
      this.wasInstallationsTabOpened = this.openedTab == 'installationTab';
      this.wasConverterTabOpened = this.openedTab == 'converterTab';
      this.wasSpotTabOpened = this.openedTab == 'spotListTab';
      this.wasPhotopointTabOpened = this.openedTab == 'photopointListTab';
      this.wasLiftOpened = this.openedTab == 'liftTab';
      this.wasSharedOpened = this.openedTab == 'sharedTab';
      this.wasRoutingRulesOpened = this.openedTab == 'routingRulesTab';
      this.wasPermissionOpened = this.openedTab == 'permissionTab';
      
      this.selectedResort = val;
      this.resort = null;
     if(val != -1) {
        this.getResort(val);
        this.getResortInstallations(val);
      }
    },
    showSidebarAdd () {
      this.$refs.converterConfigsList.showSidebarAdd();
    },
    showSpotSidebarAdd () {
      this.$refs.spotList.showSidebarAdd();
    },
    showPhotopointSidebarAdd () {
      this.$refs.photopointList.showSidebarAdd();
    },
    showLiftSidebarAdd () {
      this.$refs.resortLift.showSidebarAdd();
    },
    showSharedSidebarAdd () {
      this.$refs.sharedResortsList.showSidebarAdd();
    },
    showRoutingRulesSidebarAdd () {
      this.$refs.routingRulesList.showSidebarAdd();
    },
    getSkilineUrl () {
      if(this.resort != null && this.resort.link) {
        return "https://www.skiline.cc/resort/" + this.resort.link;
      } 
      return "";
    },
    //API
    getResortInstallations (id) {
    this.loading = true;
    this.axios.get(`/Resort/GetResortInstallations?resortId=` + id)
      .then((response) => {
        if (response) {
          this.installations = response.data;
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
    getResort (id) {
    this.loading = true;
    this.axios.get(`/Resort/GetResort?id=` + id)
      .then((response) => {
        if (response) {
          this.resort = response.data; 
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
    exportLift () {
      this.exportLoading = true;
      this.axios.post(`/Resort/GenerateLiftExport?resortId=` + this.selectedResort, this.checkedLiftFields)
      .then((response) => {
        if (response) {
          const link = document.createElement('a');
          link.href = 'data:application/octet-stream;charset=utf-8;base64,' + response.data;
          link.target = '_blank';
          link.download = 'sections_ ' + this.selectedResort + '.xlsx';
          link.click();
        }
      })
      .finally(() => {
        this.exportLoading = false;
      });
    }
  }
}
</script> 

<style scoped>
.rep-tab-pane:not(.active) {
  display: block;
  height: 0;
  overflow-y: hidden;
}

.iframe {
  width: 100%;
  height: 100vh;
}
</style>